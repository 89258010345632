import React from "react";
import { graphql } from "gatsby";
import {
  SEO,
} from '../components';
import {
  Layout,
  LayoutHeader,
  LayoutFooter,
  LayoutMain,
  Header,
  Footer,
  PageTitle,
  PageHTML,
} from '../ui-components';


const TermsPage = ({ data, location }) => {
  const content = data.markdownRemark.frontmatter || {};
  const html = data.markdownRemark.html;

  return (
    <>
      <SEO
        canonical="/terms/"
        title={content.title}
      />
      <Layout id="layout" layout="simple">
        <LayoutHeader>
          <Header />
        </LayoutHeader>
        <LayoutMain>

          <PageTitle>{content.title}</PageTitle>
          <br />
          <PageHTML dangerouslySetInnerHTML={{ __html: html }} />

        </LayoutMain>
        <LayoutFooter>
          <Footer />
        </LayoutFooter>
      </Layout>
    </>
  );
}

export default TermsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fileAbsolutePath: {regex: "/content/pages/terms.md/"}) {
      id
      frontmatter {
        title
      }
      html
    }      
  }
`;